export function seminar(rest , forms){
	const compornent = {
		data: function(){
			return {
                formcheck: false,
                formsend: false,
                mail_toggle: false,
				data:  [],
                contact: [],
                ind: 0,
                entry: {
                    company: '',
                    user: '',
                    email: '',
                    title: '',
                    tel: '',
                    return: '',
                    syomei: '',
                    message: ''
                }
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            
            axios.get(forms)
                .then(function (response) {
					$_this.entry.return = response.data.acf.email;
					$_this.entry.syomei = response.data.acf.syomei;
					$_this.entry.message = response.data.acf.message;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
        <section class="site-module wrap site-seminar bg">
            <div class="display">
                <div class="hgroup">
                    <h2 data-text="MIRAKIMI SEMINAR">みらきみセミナー</h2>
                </div>
                <transition name="scale">
                    <div class="sendmessage" v-if="formsend" v-cloak>セミナーに申込を行いました</div>
                </transition>
                <ol class="seminar-list">
                    <li v-for="(items , index) in data" @click="mail_toggle = true, ind = index , changing(), entry.title = data[index].title.rendered"><time>{{ set_date(items.date) }}</time><span class="title">{{ items.title.rendered }}</span></li>
                </ol>
            </div>

            <transition name="scale">
                <div class="overlay" v-if="mail_toggle" v-cloak>
                   <div class="close" @click="mail_toggle = false , changing()"></div>
                    <div class="inner seminar-component">
                        <h3>{{ data[ind].title.rendered }}</h3>
                        <p class="about">{{ data[ind].acf.about }}</p>   
                        <table class="table-style">
                            <tbody>
                                <tr>
                                    <th>日時</th>
                                    <td>{{ data[ind].acf.date }}</td>
                                </tr>
                                <tr>
                                    <th>場所</th>
                                    <td>{{ data[ind].acf.area }}</td>
                                </tr>
                                <tr>
                                    <th>対象</th>
                                    <td>{{ data[ind].acf.target }}</td>
                                </tr>
                                <tr>
                                    <th>締切</th>
                                    <td>{{ data[ind].acf.out }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <dl class="teacher">
                            <dt>講師</dt>
                            <dd>{{ data[ind].acf.teacher }}</dd>
                        </dl>

                        <table class="table-style form-style">
                            <tbody>
                                <tr>
                                    <th>事業所名</th>
                                    <td><input type="text" v-model="entry.company" @change="checkall()"></td>
                                </tr>
                                <tr>
                                    <th>参加者名</th>
                                    <td><textarea v-model="entry.user" @change="checkall()"></textarea></td>
                                </tr>
                                <tr>
                                    <th>代表者メールアドレス</th>
                                    <td><input type="email" v-model="entry.email" @change="checkall()"></td>
                                </tr>
                                <tr>
                                    <th>代表者電話番号</th>
                                    <td><input type="tel" v-model="entry.tel" @change="checkall()"></td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="bottoms" v-if="formcheck">
                            <div class="btns" @click="mailsend() , changing()">
                                <a class="custum-bg"><i class="fas fa-envelope"></i>送信する</a>
                            </div>
                            <p class="alert">メールを送信した時点で、プライバシーポリシーに同意したと判断致します。</p>
                        </div>
                        <div class="bottoms" v-if="!formcheck">
                            <p class="alert err">必須項目を入力してください</p>
                        </div>
                    </div>
                </div>
            </transition>
        </section>
		`,
		methods: {
			set_date(date){
				let substr = date.substring(0,10);
				return substr.replace(/-/g, '.');
			},
			changing: function(){
				const body = document.getElementById('site-body');
				const head = document.getElementById('site-header');
				body.classList.toggle('hidden');
				head.classList.toggle('hidden');
			},
			mailsend: function(){
                const post_url =  '/mail/seminar.php';
                const $_this = this;
                axios.post(post_url , this.entry)
                .then(response => {
                	if(response.data == 'success'){
						$_this.mail_toggle = false;
						setTimeout(function(){
							$_this.formsend = true;
						}, 800);
						setTimeout(function(){
							$_this.formsend = false;
						}, 2800);
					}
                }).catch(error => {
                	console.log(error);
                });
			},
			checkall: function(){
				let flag = true;
				for(let k in this.entry) {
                    if(this.entry[k] == ''){
                       flag = false;
                    }
				}
				if(!this.entry.email.match(/^([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+$/)){
					flag = false;
				}
				this.formcheck = flag;
			}
		},

	}
	
	return compornent;
}