export function kikan(rest){
	const compornent = {
		data: function(){
			return {
				data:  []
			}
		},
		created: function(){
            const $_this = this;
            axios.get(rest)
                .then(function (response) {
                    $_this.data = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
		},
		template:
		`
        <section class="site-module wrap site-coo">
            <div class="display full">
                <div class="hgroup">
                    <h2 data-text="COOPERATE">協力機関</h2>
                </div>
                <ul class="coolist">
                    <li v-for="items in data"><p>{{ items.title.rendered }}</p></li>
                </ul>
            </div>
        </section>
		`
	}
	
	return compornent;
}